const compsToPackages = {
  'wixui.Slider': () => Promise.all([import(/* webpackChunkName: "wixui.Slider" */ '../components/Slider')]),
  'wixui.AddressInput': () => Promise.all([import(/* webpackChunkName: "wixui.AddressInput" */ '../components/AddressInput')]),
  'wixui.StylableLine': () => Promise.all([import(/* webpackChunkName: "wixui.StylableLine" */ '../components/StylableLine')]),
  'wixui.RatingsInput': () => Promise.all([import(/* webpackChunkName: "wixui.RatingsInput" */ '../components/RatingsInput')]),
  'wixui.TimePicker': () => Promise.all([import(/* webpackChunkName: "wixui.TimePicker" */ '../components/TimePicker')])
  //'wixui.baseui.ToggleSwitch': () => Promise.all([import(/* webpackChunkName: "wixui.baseui.ToggleSwitch" */ '../baseUI/components/BaseUIToggleSwitch')])
};

const setWebpackPublicPath = baseUrl => {
  __webpack_public_path__ = `${baseUrl}/`; // eslint-disable-line
};

const exists = componentType => !!compsToPackages[componentType];

const loadComponent = componentType => {
  if (!exists(componentType)) {
    return Promise.reject(new Error(`no such component ${componentType}`));
  }
  return compsToPackages[componentType]();
};

const loadAll = baseUrl => {
  setWebpackPublicPath(baseUrl);
  return Promise.all(Object.keys(compsToPackages).map(loadComponent));
};

const load = (componentType, baseUrl) => {
  setWebpackPublicPath(baseUrl);
  return loadComponent(componentType);
};

module.exports = {
  exists,
  load,
  loadAll
};
